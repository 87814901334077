
import { createRouter, createWebHistory } from 'vue-router';
import DashboardView from '../views/DashboardView.vue';
import CoursesList from '../views/CoursesList.vue';
import TeamProgress from '../views/TeamProgress.vue';

const routes = [
  { path: '/', name: 'Dashboard', component: DashboardView },
  { path: '/courses', name: 'Courses', component: CoursesList },
  { path: '/team', name: 'TeamProgress', component: TeamProgress },
  // Add more routes as needed
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
