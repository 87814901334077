<template>
  <v-container fluid>
    <!-- Dashboard Title and Overview -->
    <v-row>
      <v-col cols="12">
        <h1 class="text-h3 text-center py-4">LMS Dashboard: Insights & Analytics</h1>
        <transition name="fade">
          <v-alert v-if="showAlert" type="info" dense>
            <v-row align="center" justify="space-between" no-gutters>
              <v-col cols="auto">{{ randomMinutes }} minutes since a user last attempted a quiz.</v-col>
              <v-col cols="auto">
                <v-btn small text color="deep-purple accent-4" @click="dismissAlert">
                  Dismiss
                </v-btn>
              </v-col>
            </v-row>
          </v-alert>
        </transition>
      </v-col>
    </v-row>

    <!-- Interactive Summary Cards -->
    <v-row class="my-5">
      <v-col cols="12" md="4" v-for="card in summaryCards" :key="card.title">
        <v-card elevation="5" class="pa-5">
          <v-card-title class="headline">{{ card.title }}</v-card-title>
          <v-card-subtitle>{{ card.subtitle }}</v-card-subtitle>
          <v-spacer></v-spacer>
          <v-card-text class="display-2">{{ card.value }}</v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- Dynamic Charts Section -->
    <v-row>
      <v-col cols="12" md="6">
        <v-card class="pa-4 elevation-5">
          <v-card-title>Course Completion Trends</v-card-title>
          <v-card-text><canvas ref="lineChart"></canvas></v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-card class="pa-4 elevation-5">
          <v-card-title>Course Category Popularity</v-card-title>
          <v-card-text><canvas ref="pieChart"></canvas></v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- Progress Indicators -->
    <v-row class="my-5">
      <v-col cols="12" md="6">
        <v-card elevation="5" class="pa-4" height="100%">
          <v-card-title>Monthly Enrollment</v-card-title>
          <v-progress-linear :value="70" color="teal" striped></v-progress-linear>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-card elevation="5" class="pa-4" height="100%">
          <v-card-title>Learner Satisfaction</v-card-title>
          <v-progress-circular rotate="-90" size="100" width="15" value="85" color="amber">85%</v-progress-circular>
        </v-card>
      </v-col>
    </v-row>

    <!-- Recent Activities Section -->
    <v-row>
      <v-col cols="12">
        <v-card elevation="5" class="pa-3">
          <v-card-title>Recent Activity</v-card-title>
          <v-timeline dense>
            <v-timeline-item v-for="activity in recentActivities" :key="activity.id" color="deep-purple accent-4" small>
              <template v-slot:opposite>{{ activity.time }}</template>
              {{ activity.description }}
            </v-timeline-item>
          </v-timeline>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>




<script>
import Chart from 'chart.js';

export default {
  name: 'DashboardView',
  data() {
    return {
      randomMinutes: Math.floor(Math.random() * 60) + 1,  
      showAlert: true,    
      summaryCards: [
        { title: 'Total Courses', subtitle: 'Courses Available', value: '25' },
        { title: 'Active Learners', subtitle: 'Learners Enrolled', value: '150' },
        { title: 'Completed Courses', subtitle: 'Courses Finished', value: '120' },
      ],
      recentActivities: [
        { id: 1, time: '2 mins ago', description: 'Alex Johnson completed Course #01' },
        { id: 2, time: '10 mins ago', description: 'Sam Taylor enrolled in Course #05' },
        { id: 3, time: '30 mins ago', description: 'Riley Brown finished Course #03' },
        { id: 4, time: '1 hour ago', description: 'Jordan Smith started Course #02' },
      ]
    };
  },
  mounted() {
    this.initLineChart();
    this.initPieChart();
  },
  methods: {
    dismissAlert() {
      this.showAlert = false;
    },    
    initLineChart() {
      const ctx = this.$refs.lineChart.getContext('2d');
      new Chart(ctx, {
        type: 'line',
        data: {
          labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
          datasets: [{
            label: 'Courses Completed',
            backgroundColor: 'rgba(66, 165, 245, 0.5)',
            borderColor: '#42A5F5',
            data: [40, 20, 30, 10, 50, 70]
          }]
        },
        options: { responsive: true, maintainAspectRatio: false }
      });
    },
    initPieChart() {
      const ctx = this.$refs.pieChart.getContext('2d');
      new Chart(ctx, {
        type: 'pie',
        data: {
          labels: ['Business', 'Technology', 'Art', 'Science'],
          datasets: [{
            backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0'],
            data: [10, 30, 20, 40]
          }]
        },
        options: { responsive: true, maintainAspectRatio: false }
      });
    },
    // Additional methods for other functionalities
  }
};
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}
/* Custom styles for enhanced visualization */
</style>
