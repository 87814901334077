<template>
  <v-app>
    <!-- Navigation Bar -->
    <v-app-bar app color="deep-purple accent-4" dark>
      <v-toolbar-title>WOWTRAIN! Portal</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn text to="/">Dashboard</v-btn>
      <v-btn text to="/learners">Learners</v-btn>      
      <v-btn text to="/courses">Courses</v-btn>
      <v-btn text to="/team">Team Progress</v-btn>
    </v-app-bar>

    <!-- Main Content -->
    <v-main>
      <v-container>
        <router-view></router-view>
      </v-container>
    </v-main>

    <!-- Footer -->
    <v-footer color="indigo" app>
      <span class="white--text">&copy; 2024 Team LMS</span>
    </v-footer>
  </v-app>
</template>

<script>
export default {
  name: 'App',
}
</script>

<style>
/* Custom Styles */
</style>
