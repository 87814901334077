<template>
  <div>
    <h1>Courses</h1>
    <p>List of available courses.</p>
    <!-- Course List Here -->
  </div>
</template>

<script>
export default {
  name: 'CoursesList',
}
</script>
